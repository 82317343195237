import React from 'react';
import { IonPage, IonContent, IonLabel, isPlatform, IonImg, IonButton } from '@ionic/react';
import { CameraPreview } from '@capacitor-community/camera-preview';
import WebCam from 'react-webcam';
import { Subscription } from 'rxjs';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { t } from 'i18next';

import Step, { WorkflowProps } from './Step';
import { PathName, ScreenType } from '../../commons/Constants';
import Utils from '../../utils/Utils';
import GreetlyIcon from '../../components/GreetlyIcon';
import '../../theme/pages/photoCapture.css';
import CameraUtils from '../../utils/CameraUtils';

type State = {
  shouldRedirectToUnknownError: boolean;
  shouldShowWarning: boolean;
  isCountdown: boolean;
  remainingTimeSeconds: number;
  isInCountdown: boolean;
  shouldShowCamera: boolean;
  isReviewRequired: boolean;
  imageBase64: string | null;
  isCameraFront: boolean;
};

class PhotoCapture extends Step<WorkflowProps, State> {
  state: State = {
    shouldRedirectToUnknownError: false,
    shouldShowWarning: false,
    isCountdown: this.props.appStore.locationStore!.location?.terminologySetting.photoCountdown !== -2,
    remainingTimeSeconds: this.props.appStore.locationStore!.location?.terminologySetting.photoCountdown!,
    isInCountdown: false,
    shouldShowCamera: false,
    isReviewRequired: this.props.node.data.is_review_before_submission === true,
    imageBase64: null,
    isCameraFront: true,
  };

  webCamRef = React.createRef<WebCam & HTMLVideoElement>();
  private screenOrientationChangeSubscription: Subscription | null = null;

  async componentDidMount() {
    if (Utils.isNative()) {
      const cameraSide = this.state.isCameraFront ? 'front' : 'rear';

      this.screenOrientationChangeSubscription = ScreenOrientation.onChange().subscribe(() =>
        this.restartNativeCamera(cameraSide),
      );
      this.startNativeCamera(cameraSide);
    } else {
      this.setupMediaDevice()
        .then(() => {
          this.setState({ shouldShowCamera: true }, this.initializeCountdownTimerIfNeeded);
        })
        .catch(() => {
          this.setState({ shouldShowWarning: true });
        });
    }
  }

  async componentWillUnmount() {
    if (Utils.isNative()) {
      this.screenOrientationChangeSubscription?.unsubscribe();
      const root = document.documentElement;
      this.stopNativeCamera();
      root.style.setProperty('--ion-background-color', 'var(--greetly-background-color)');
    }
  }

  startNativeCamera(cameraSide = 'front') {
    let width: number | undefined = undefined;
    let height: number | undefined = undefined;
    if (isPlatform('ios')) {
      if (
        ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE ||
        ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY ||
        ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY
      ) {
        width = window.screen.height;
        height = window.screen.width;
      }
    }

    CameraPreview.start({
      width: width,
      height: height,
      position: cameraSide,
      toBack: true,
      disableExifHeaderStripping: window.innerWidth > window.innerHeight,
    }).then(() => {
      const root = document.documentElement;
      root.style.setProperty('--ion-background-color', 'transparent');

      this.setState({ shouldShowCamera: true }, this.initializeCountdownTimerIfNeeded);
    });
  }

  stopNativeCamera() {
    return CameraPreview.stop();
  }

  restartNativeCamera(cameraSide = 'front') {
    this.stopNativeCamera().then(() => {
      this.startNativeCamera(cameraSide);
    });
  }

  flipNativeCamera() {
    this.setState({ isCameraFront: !this.state.isCameraFront }, () => {
      this.restartNativeCamera(this.state.isCameraFront ? 'front' : 'rear');
    });
  }

  takePictureCountdown = () => {
    if (this.state.remainingTimeSeconds <= 0) {
      this.setState({ remainingTimeSeconds: 0, isInCountdown: false }, () => {
        this.takePicture();
      });
    } else {
      setTimeout(() => {
        this.setState({ remainingTimeSeconds: this.state.remainingTimeSeconds - 1 }, () => {
          this.takePictureCountdown();
        });
      }, 1000);
    }
  };

  _setImageClosePage(imageBase64: string) {
    const { workflowStore } = this.props.appStore;

    if (workflowStore.workflow?.useAlternativeWorkflow === true) {
      workflowStore.setVisitorImageBase64(imageBase64);
      workflowStore.goToNextNode();
    } else {
      workflowStore.setVisitorImageBase64(imageBase64);
      workflowStore.goToNextStep();
    }
  }

  takePicture = () => {
    if (this.state.isInCountdown) {
      return;
    }

    let _finishWithImage = (imageBase64: string) => {
      if (!this.state.isReviewRequired) {
        this._setImageClosePage(imageBase64);
      } else {
        this.stopNativeCamera();
        this.setState({ imageBase64: imageBase64 });
      }
    };

    if (Utils.isNative()) {
      CameraPreview.capture({
        quality: 100,
      })
        .then((result: { value: string }) => {
          _finishWithImage(result.value);
        })
        .catch(() => {
          Utils.showErrorToast('An error has occurred');
        });
    } else {
      const imageBase64Url = this.webCamRef.current!.getScreenshot();
      if (imageBase64Url !== null) {
        const imageBase64 = imageBase64Url.substring(imageBase64Url.lastIndexOf(',') + 1, imageBase64Url.length);
        _finishWithImage(imageBase64);
      } else {
        this.setState({ shouldRedirectToUnknownError: true });
      }
    }
  };

  async retakePhoto() {
    const cameraSide = this.state.isCameraFront ? 'front' : 'rear'
    this.setState({
      imageBase64: null,
      remainingTimeSeconds: this.props.appStore.locationStore!.location?.terminologySetting.photoCountdown!,
    });
    this.initializeCountdownTimerIfNeeded();

    if (Utils.isNative()) {
      this.startNativeCamera(cameraSide);
    } else {
      this.setupMediaDevice()
        .then(() => {
          this.setState({ shouldShowCamera: true }, this.initializeCountdownTimerIfNeeded);
        })
        .catch(() => {
          this.setState({ shouldShowWarning: true });
        });
    }
  }

  private initializeCountdownTimerIfNeeded = () => {
    if (this.state.isCountdown) {
      if (!this.state.isInCountdown) {
        this.setState({ isInCountdown: true }, () => {
          this.takePictureCountdown();
        });
      }
    }
  };

  private setupMediaDevice = () => {
    return navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'user', height: { ideal: 4096 }, width: { ideal: 2160 } },
    });
  };

  render() {
    if (!Utils.isNative()) {
      if (this.state.shouldRedirectToUnknownError) {
        return <Redirect to={PathName.UNKNOWN_ERROR} />;
      }
    }

    const { locationStore } = this.props.appStore;

    return (
      <IonPage className="photocapture">
        <IonContent scroll-y="false">
          {this.state.shouldShowWarning ? (
            <>
              <div className="warning">
                {/* @TODO styling */}
                Photo is required. Please allow the app to access the camera and try again.
              </div>
            </>
          ) : (
            <>
              {this.state.isReviewRequired && this.state.imageBase64 != null ? (
                <div className="photo-container">
                  <div>
                    <IonImg className="photo-image" src={'data:image/jpeg;base64, ' + this.state.imageBase64} />
                    <IonButton
                      className="generic-button"
                      onClick={() => this._setImageClosePage(this.state.imageBase64!)}
                      style={{ position: 'absolute', bottom: 0, right: '10%' }}
                    >
                        <div>{t('next')}</div>
                    </IonButton>
                    <IonButton
                      className="generic-button-cancel"
                      onClick={() => this.retakePhoto()}
                      style={{ position: 'absolute', bottom: 0, left: '10%' }}
                    >
                      {this.props.appStore.screenType === ScreenType.Phone && <GreetlyIcon iconName="rotate-left" />}
                      {this.props.appStore.screenType === ScreenType.Tablet && 'RE-TAKE'}
                    </IonButton>
                  </div>
                </div>
              ) : (
                this.state.shouldShowCamera && (
                  <>
                    {!Utils.isNative() && (
                      <div className="cam-cont">
                        <WebCam
                          className="cam-prev"
                          ref={this.webCamRef}
                          audio={false}
                          mirrored={true}
                          screenshotFormat="image/jpeg"
                          videoConstraints={{
                            facingMode: 'user',
                          }}
                        ></WebCam>
                      </div>
                    )}
                    <div className="countdown-container">
                      {this.state.isCountdown && this.state.remainingTimeSeconds > 0 ? (
                        <IonLabel className="countdown-text">{this.state.remainingTimeSeconds}</IonLabel>
                      ) : null}
                    </div>
                    <div className="capture-container">
                      {!this.state.isCountdown && (
                        <div>
                          <IonLabel className="prev-text">{locationStore.tabletTerminologies.smile}</IonLabel>
                          <IonButton className="flip-btn" onClick={() => this.flipNativeCamera()}>
                            Flip Camera
                          </IonButton>
                          <button className="capture-button" onClick={() => this.takePicture()} />
                        </div>
                      )}
                      {this.state.isCountdown && (
                        <IonLabel className="prev-text">{locationStore.tabletTerminologies.smileCount}</IonLabel>
                      )}
                    </div>
                  </>
                )
              )}
            </>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default observer(PhotoCapture);
